<template lang="pug">
.brand-wrapper.experiment-page(v-if="!loadingExperiment")
  .container-fluid
    .row.title
      .col-12.p-0
        .experiment-header
          editable-title(
            h1
            v-if="experiment"
            :title="experiment.name"
            @renameTitle="renameExperiment"
          )
          template(v-if="canChangeDomain")
            template(v-if="!experiment.domain")
              .brand-link(@click="$modal.show('domain-management')") {{ $t('setupADomain') }}
            template(v-else)
              template(v-if="domainCount === 1")
                .domain-editing
                  input(class="dynamic-input campaign-name-ellipsis inline-edit"
                        type="text"
                        v-autowidth="{minWidth: '20px', comfortZone: 20}"
                        v-model="newDomain"
                        @keydown.27="cancel"
                        @change="renameDomain"
                        @focus="$refs.dynamicDomainInput.select()"
                        ref="dynamicDomainInput")
              template(v-else-if="domainCount > 1")
                .domain-editing(@click="$modal.show('domain-management')")
                  span.mr-2.py-2.inline-edit {{ experiment.domain }}
          template(v-else-if="experiment")
            span.mr-2.py-2 {{ experiment.domain }}

    .row
      .col-12.start-stop-experiment.bordered-content(@mouseleave="closeKebabMenu({_id: experiment._id})")
        .experiment-status
          om-chip.m-0( :color="chipColor" :icon="chipIcon(experiment.status)" large) {{experimentStatus}}
          .experiment-duration.d-flex(v-if="experiment.status !== 'DRAFT'")
            om-body-text(bt400sm) {{ `${$t('experiments.duration')}: ${getDuration(experiment)}` }}
            .d-flex.experiment-duration-right
              om-body-text(bt400sm) {{ experimentStarted }}
              om-body-text.ml-3(bt400sm v-if="experiment.status === 'FINISHED'") {{ experimentFinished }}
        .experiment-actions
          om-button#start-experiment.mr-4(primary icon="play-circle" v-if="experiment.status === 'DRAFT'" :disabled="!isStartEnabled" @click="startExperiment()")
            span {{ $t('experiments.start') }}
          om-button#end-experiment.mr-4(primary icon="stop-circle" v-if="experiment.status === 'RUNNING'" @click="stopExperiment()")
            span {{ $t('experiments.end') }}
          om-kebab-menu(
            :ref="`kebab_${experiment._id}`"
            :rowData="{row: {_id: experiment._id}}"
            @delete="deleteExperiment(experiment._id, { isDetailsPage: true })")
            template(slot="delete") {{ $t('delete') }}
            template(slot="custom")
              .kebab-option.kebab-add-js(@click="updateTrafficShare()") {{ $t('experiments.editTrafficShare') }}

      .col-12.visitor-groups.bordered-content.pb-2(v-if="experiment")
        .groups-header.d-flex
          om-heading(h3) {{ $t('experiments.visitorGroups') }}
          .d-flex
            om-select#goal.mr-2.goal-select(
              v-if="!shouldShowDefineGoalButton"
              v-model="activeGoal"
              :options="allGoals"
              optionKey="_id"
              optionText="name"
              :label="$t('conversionGoals.selectLabel')"
              labelPosition="fill"
              extendable
              :addNewText="$t('conversionGoals.addNewText')"
              @addNew="openCreateGoalModal(experiment.domainId)"
            )
            om-tooltip.mr-2.d-flex.align-items-center(placement="top")
              span {{ $t('conversionGoals.tooltip') }}
            om-button.mr-3(
              secondary
              small
              v-if="shouldShowDefineGoalButton"
              @click="openCreateGoalModal(experiment.domainId)"
            ) {{ $t('conversionGoals.defineConversionGoal') }}
            om-button#add-group-to-experiment(
              secondary
              :disabled="isAddGroupDisabled"
              @click="addGroupToExperiment()"
            ) {{ $t('experiments.addNewGroup') }}
        .table.brand-table.experiment-table.mb-0
          .thead.mb-0
            .tr
              template(v-for="(column, index) in columns")
                .th.brand-table-th(scope="col" :class="[`experiment-table-col-${column.key}`, `brand-table-th-${index}`]")
                  span {{ column.header }}

          .tbody.mb-0
            template(v-for="(group, index) in experimentGroups")
              .tr.brand-table-tr.brand-table-tr-header(:key="index" @mouseleave="closeKebabMenu({_id: group._id})")
                .td.brand-table-td.experiment-table-row-trafficShare.brand-table-td-0
                  om-button#update-traffic-share.ml-2(small secondary :disabled="updateTrafficShareDisabled" @click="updateTrafficShare()") {{ `${getTrafficShareInPercentage(group.trafficShare)}%` }}
                .td.brand-table-td.experiment-table-row-campaigns.brand-table-td-1 {{ group.name }}
                  insert-code.insert-code(v-if="groupHasCode(group)" @click.native="showCodeEditor(group)" color="#505763")
                .td.brand-table-td.experiment-table-row-status.brand-table-td-2
                .td.brand-table-td.experiment-table-row-device.brand-table-td-3
                .td.brand-table-td.experiment-table-row-impressions.brand-table-td-4 {{ (group.stats && group.stats.impressions) || 0 }}
                .td.brand-table-td.experiment-table-row-conversions.brand-table-td-5
                  vue-skeleton-loader.skeleton-goals(
                    v-if="loadingGoals"
                    type="rect"
                    width="50%"
                    height="24px"
                    :rounded="true"
                  )
                  template(v-else) {{  group.stats && group.stats.conversions }}
                .td.brand-table-td.experiment-table-row-conversionRate.brand-table-td-6
                  vue-skeleton-loader.skeleton-goals(
                    v-if="loadingGoals"
                    type="rect"
                    width="50%"
                    height="24px"
                    :rounded="true"
                  )
                  template(v-else) {{ formatConversionRate(group.stats) }}
                .td.brand-table-td.experiment-table-row-actions.brand-table-td-7.remove-group-col
                  om-kebab-menu.mr-3(
                      :ref="`kebab_${group._id}`"
                      :rowData="{row: {_id: group._id}}"
                      @delete="removeGroupFromExperiment(group._id)"
                      )
                    template(slot="custom")
                      .kebab-option.kebab-add-js(@click="showCodeEditor(group)") {{ $t('experiments.modal.addJsSnippet') }}
                    template(slot="delete" v-if="canGroupBeDeleted()") {{ $t('delete') }}
              template(v-for="campaign in group.campaigns")
                .tr.brand-table-tr(:key="`${group._id}-${campaign.id}`")
                  .td.brand-table-td.brand-table-td-0
                  .td.brand-table-td.experiment-campaigns.brand-table-td-1
                    variant-preview(
                      :key="`${group._id}-${campaign.id}`"
                      @observable="addObservable($event.$el)"
                      :allowSsr="isCampaignsWithSsr"
                      :dimensions="boxDimensions"
                      :campaign="campaign"
                      :transparentOverlay="false"
                      :templateType="campaign.templateType"
                      @click="$router.push({ name: 'campaign_variants', params: { id: campaign.id } })"
                    )
                    router-link.ml-3(:to="{ name: 'campaign_variants', params: { id: campaign.id } }") {{ campaign.name }}
                  .td.brand-table-td.brand-table-td-2
                    toggle-button.mb-0.mt-0.in-experiment(
                      :labels="{checked: $t('active'), unchecked: $t('inactive') }"
                      :value="campaign.status === 'active'"
                      :style="{'pointer-events':'none'}"
                      :width="88"
                      :height="24"
                      :sync="true"
                      :margin="1")
                  .td.brand-table-td.brand-table-td-3
                    om-device(:type="campaign.device")
                  .td.brand-table-td.brand-table-td-4.experiment-table-row-campaign {{ campaign.impressions || 0 }}
                  .td.brand-table-td.brand-table-td-5.experiment-table-row-campaign
                    vue-skeleton-loader.skeleton-goals(
                      v-if="loadingGoals"
                      type="rect"
                      width="50%"
                      height="24px"
                      :rounded="true"
                    )
                    template(v-else) {{ campaign.conversions || 0 }}
                  .td.brand-table-td.brand-table-td-6.experiment-table-row-campaign
                    vue-skeleton-loader.skeleton-goals(
                      v-if="loadingGoals"
                      type="rect"
                      width="50%"
                      height="24px"
                      :rounded="true"
                    )
                    template(v-else) {{ formatConversionRate(campaign) }}
                  .td.brand-table-td.brand-table-td-7.remove-campaign-col
                    .remove-campaign.mr-3(v-if="experiment.status === 'DRAFT'")
                      om-tooltip(placement="top-end" :arrow="false")
                        span Remove from experiment
                        template(slot="trigger")
                          om-button(
                            ghost
                            iconOnly
                            small
                            icon="trash-alt"
                            @click="removeCampaignFromGroup(campaign.id, group._id)"
                          )
              .tr.brand-table-tr(v-if="experiment.status === 'DRAFT'" :style="{'min-height': '4rem'}")
                .td.brand-table-td.brand-table-td-0
                .td.d-flex.add-campaign.align-items-center.brand-table-td-1(@click="addCampaign(group)")
                  UilPlusCircle.mr-2(size="20px")
                  span {{ $t('experiments.addCampaign') }}
                .td.brand-table-td(v-for="index in 6" :key="index" :class="`brand-table-td-${index+1}`")

  domain-management(v-if="experiment" :domain.sync="experiment.domain"
                  :domainId.sync="experiment.domainId"
                  @refetch="onDomainRefetch" :domainDeletable="false")

  EndExperiment
  AddCampaign
  AddCampaignToExperiment
  DeleteExperiment
  TrafficShare(@experiments:trafficShareUpdate="updateGroups")
  CodeEditor(@updateJsSnippetInGroup="updateJsSnippetInGroup")
  create-edit-goal(@goals:createGoal="createNewGoal")
</template>
<script>
  import GET_EXPERIMENT from '@/graphql/GetExperiment.gql';
  import { mapGetters, mapMutations } from 'vuex';
  import EditableTitle from '@/components/EditableTitle.vue';
  import DomainManagement from '@/components/Modals/DomainManagement.vue';
  import TrafficShare from '@/components/Experiments/Modal/TrafficShare.vue';
  import GET_DOMAINS_COUNT from '@/graphql/GetDomainsCount.gql';
  import UPDATE_EXPERIMENT from '@/graphql/UpdateExperiment.gql';
  import { validateDomain, removeProtocolFromDomain } from '@/util';
  import GET_DOMAIN_USAGE_COUNT from '@/graphql/GetDomainUsageCount.gql';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import RENAME_DOMAIN from '@/graphql/RenameDomain.gql';
  import runTimeConfig from '@/config/runtime';
  import EndExperiment from '@/components/Experiments/Modal/EndExperiment.vue';
  import AddCampaign from '@/components/Experiments/Modal/AddCampaign.vue';
  import CodeEditor from '@/components/Experiments/Modal/CodeEditorModal.vue';
  import OmDevice from '@/components/OmDevice';
  import InsertCode from '@/assets/admin/svg/InsertCode.vue';
  import CreateEditGoal from '@/components/Modals/CreateEditGoal.vue';

  import {
    UilPlusCircle,
    UilTrashAlt,
    UilPlayCircle,
    UilStopCircle,
    UilEdit,
    UilStopwatch,
    UilCheckCircle,
  } from '@iconscout/vue-unicons';
  import AddCampaignToExperiment from '@/components/Modals/AddCampaignToExperiment.vue';
  import ADD_CAMPAIGN_TO_EXPERIMENT from '@/graphql/AddCampaignToExperiment.gql';
  import REMOVE_CAMPAIGN_FROM_EXPERIMENT from '@/graphql/RemoveCampaignFromExperiment.gql';
  import ADD_GROUP_TO_EXPERIMENT from '@/graphql/AddGroupToExperiment.gql';
  import DELETE_GROUP_FROM_EXPERIMENT from '@/graphql/DeleteGroupFromExperiment.gql';
  import experiments from '@/mixins/experiments';
  import experimentTrafficShare from '@/mixins/experimentTrafficShare';
  import DeleteExperiment from '@/components/Experiments/Modal/DeleteExperiment.vue';
  import START_EXPERIMENT from '@/graphql/StartExperiment.gql';
  import END_EXPERIMENT from '@/graphql/EndExperiment.gql';
  import moment from 'moment';
  import dateFormat from '@/mixins/dateFormat';
  import VariantPreview from '@/components/Template/VariantPreview.vue';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import numeral from 'numeral';
  import GET_GOALS from '@/graphql/GetGoals.gql';
  import GET_GOAL_BY_EXPERIMENT from '@/graphql/GetGoalByExperiment.gql';
  import goalsMixin from '@/mixins/goals';
  import closeKebabMenu from '@/components/Elements/KebabMenu/closeKebabMenu';

  const DEFAULT_GOAL_BY_DOMAIN_KEY = 'defaultGoalsPerDomain';

  export default {
    components: {
      TrafficShare,
      OmDevice,
      EditableTitle,
      DomainManagement,
      EndExperiment,
      AddCampaign,
      UilPlusCircle,
      UilTrashAlt,
      UilPlayCircle,
      UilStopCircle,
      UilEdit,
      UilStopwatch,
      UilCheckCircle,
      AddCampaignToExperiment,
      DeleteExperiment,
      VariantPreview,
      CodeEditor,
      InsertCode,
      CreateEditGoal,
    },
    mixins: [
      experiments,
      experimentTrafficShare,
      dateFormat,
      ssrMixin,
      observableCollectionMixin,
      goalsMixin,
      closeKebabMenu,
    ],
    data() {
      return {
        experiment: null,
        loadingExperiment: true,
        ssrBoxSelector: '.template-preview',
        newDomain: '',
        columns: [
          { header: this.$t('experiments.trafficShare'), key: 'trafficShare' },
          { header: this.$t('campaigns'), key: 'campaigns' },
          { header: this.$t('status'), key: 'status' },
          { header: this.$t('experiments.device'), key: 'device' },
          { header: this.$t('impressions'), key: 'impressions' },
          { header: this.$t('conversions'), key: 'conversions' },
          { header: this.$t('conversionRate'), key: 'conversionRate' },
          { header: '', key: 'actions' },
        ],
        goals: [],
        loadingGoals: false,
        goalConversions: {},
      };
    },
    computed: {
      ...mapGetters(['domains']),
      activeGoal: {
        get() {
          const defaultConversionGoal = JSON.parse(JSON.stringify(this.defaultGoal));

          if (this.selectedGoal) {
            return this.selectedGoal;
          }
          const defaultFromLs = this.getDefaultGoalFromLS(
            this.experiment.domainId,
            DEFAULT_GOAL_BY_DOMAIN_KEY,
          );
          if (defaultFromLs) {
            const lastViewedGoal = this.goals.find((goal) => goal._id === defaultFromLs);
            if (lastViewedGoal) {
              this.getGoal({
                goalId: lastViewedGoal._id,
              });
            }
            return lastViewedGoal;
          }

          return defaultConversionGoal;
        },
        set(goal) {
          this.selectedGoal = goal;
        },
      },
      domainId() {
        return this.experiment.domainId;
      },
      canChangeDomain() {
        return this.experiment?.status === 'DRAFT' && !this.experiment?.campaigns?.length;
      },
      isAddGroupDisabled() {
        return this.experiment?.status !== 'DRAFT' || this.experiment.groups.length >= 5;
      },
      isStartEnabled() {
        const hasCampaigns = this.experiment.groups.some((group) => group.campaigns.length >= 1);
        return this.experiment.groups.length >= 1 && hasCampaigns;
      },
      chipColor() {
        return this.experiment.status === 'RUNNING' ? 'green' : 'secondary';
      },
      experimentStatus() {
        const status = this.experiment.status;
        return this.$t(`experiments.${status.toLowerCase()}`);
      },
      experimentStarted() {
        return `${this.$t('experiments.started')}: ${moment(this.experiment.startedAt).format(
          this.dateFormat,
        )}`;
      },
      experimentFinished() {
        return `${this.$t('experiments.finished')}: ${moment(this.experiment.finishedAt).format(
          this.dateFormat,
        )}`;
      },
      updateTrafficShareDisabled() {
        return this.experiment.status !== 'DRAFT' || this.experiment.groups.length < 2;
      },
      experimentGroups() {
        if (this.activeGoal?._id !== 'defaultGoal') {
          return this.experiment.groups.map((group) => {
            const groupGoal = this.getGroupGoal(group._id);
            return {
              ...group,
              stats: {
                ...group.stats,
                conversions: groupGoal?.goalCount || 0,
                conversionRate:
                  groupGoal?.goalCount && group.stats.impressions
                    ? groupGoal?.goalCount / group.stats.impressions
                    : 0,
              },
              campaigns: group?.campaigns?.map((campaign) => {
                const campaignGoal = this.getCampaignGoal(groupGoal, campaign.id);
                return {
                  ...campaign,
                  conversions: campaignGoal?.goalCount || 0,
                  conversionRate:
                    campaignGoal?.goalCount && campaign?.impressions
                      ? campaignGoal?.goalCount / campaign?.impressions
                      : 0,
                };
              }),
            };
          });
        }

        return this.experiment.groups;
      },
    },
    watch: {
      async selectedGoal(selectedGoal) {
        this.saveDefaultGoalToLS(
          selectedGoal._id,
          this.experiment.domainId,
          DEFAULT_GOAL_BY_DOMAIN_KEY,
        );
        if (selectedGoal?._id !== 'defaultGoal') {
          await this.createMaterializedViewIfNecessary(
            selectedGoal._id,
            this.goals,
            this.experiment.domainId,
          );

          await this.getGoal({
            goalId: selectedGoal._id,
          });
        }
      },
      'experiment.domainId': async function (newValue, oldValue) {
        if (oldValue && oldValue !== newValue) {
          this.updateExperimentDomain(newValue);
        }
      },
      'experiment.groups': {
        handler() {
          this.$nextTick(() => {
            this.updateDimensions();
          });
        },
        deep: true,
      },
    },
    async created() {
      this.showAdminLoader(true);
      await this.getExperiment();

      if (this.experiment.status === 'RUNNING') {
        this.refreshGoalsMaterialisedView(this.experiment.domainId);
      }

      this.getGoals();
    },

    mounted() {
      this.$bus.$on('experiments:campaignAdded', this.handleAddCampaign);
      this.$bus.$on('experiments:experimentEnded', this.handleEndExperiment);
    },

    beforeDestroy() {
      this.$bus.$off('experiments:campaignAdded');
      this.$bus.$off('experiments:experimentEnded');
    },

    methods: {
      getGroupGoal(groupId) {
        const goalId = this.activeGoal?._id;
        return this.goalConversions?.[goalId]?.goal?.groups?.find(
          (group) => group.groupId === groupId,
        );
      },
      getCampaignGoal(goalsByGroup, campaignId) {
        return goalsByGroup?.campaigns?.find(
          (campaign) => campaign.campaignId.toString() === campaignId.toString(),
        );
      },
      async getGoals() {
        const {
          data: { goals },
        } = await this.$apollo.query({
          query: GET_GOALS,
          variables: { domainId: this.experiment.domainId, includeRules: false },
        });

        this.goals = [...goals.goals, ...[this.defaultGoal]];
      },
      async getGoal({ goalId }) {
        this.loadingGoals = true;

        if (this.goalNotInLocalCache(goalId) && goalId !== 'defaultGoal') {
          const {
            data: { goalByExperiment },
          } = await this.$apollo.query({
            query: GET_GOAL_BY_EXPERIMENT,
            variables: { goalId, experimentId: this.experiment._id },
          });

          this.$set(this.goalConversions, goalId, {
            goal: goalByExperiment,
            expire: Date.now() + this.GOAL_TTL,
          });
        }
        this.loadingGoals = false;
      },
      async getExperiment() {
        this.experimentId = this.$route.params.experimentId;
        const {
          data: { experiment },
        } = await this.$apollo.query({
          query: GET_EXPERIMENT,
          variables: {
            experimentId: this.experimentId,
          },
        });

        if (experiment) {
          this.experiment = {
            ...experiment,
            domain: this.domains.find((domain) => domain._id === experiment.domainId)?.domain || '',
          };
          this.$nextTick(() => {
            this.updateDimensions();
          });
          this.newDomain = this.experiment.domain;
          this.showAdminLoader(false);
        }
        this.loadingExperiment = false;
      },
      async createNewGoal(goal) {
        await this.upsertGoal(goal);
        this.setSelectedGoalToNew();
      },
      ...mapMutations(['showAdminLoader']),
      canGroupBeDeleted() {
        return this.experiment.status === 'DRAFT' && this.experiment.groups.length > 2;
      },
      groupHasCode(group) {
        return group?.jsSnippet && group?.jsSnippet.length >= 1;
      },
      showTrafficShare() {
        this.$modal.show('traffic-share');
      },
      showCodeEditor(group) {
        this.$modal.show('code-editor', { group });
      },
      updateJsSnippetInGroup({ groupId, jsSnippet }) {
        const group = this.experiment?.groups.find((e) => e._id === groupId);
        this.$set(group, 'jsSnippet', jsSnippet);
      },
      chipIcon(status) {
        switch (status) {
          case 'DRAFT':
            return 'edit';
          case 'RUNNING':
            return 'stopwatch';
          case 'FINISHED':
            return 'check-circle';
        }
      },
      async renameExperiment(newExperimentName) {
        if (!newExperimentName.length) {
          return;
        }

        try {
          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_EXPERIMENT,
            variables: {
              experimentId: this.experimentId,
              name: newExperimentName,
            },
          });

          if (data?.updateExperiment?.success) {
            this.experiment.name = newExperimentName;
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.saveError'),
            });
          }
        } catch (e) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.saveError'),
          });
        }
      },
      async updateExperimentDomain(domainId) {
        const experimentId = this.$route.params.experimentId;
        try {
          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_EXPERIMENT,
            variables: {
              experimentId,
              domainId,
            },
          });

          if (data) {
            const {
              updateExperiment: { success },
            } = data;

            if (success) {
              this.experiment.domainId = domainId;
              this.experiment.domain = this.domains.find(
                (domain) => domain._id === domainId,
              )?.domain;
              this.newDomain = this.experiment.domain;
              return;
            }
            this.$notify({
              type: 'error',
              text: this.$t('notifications.saveError'),
            });
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.saveError'),
            });
          }
        } catch (e) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.saveError'),
          });
        }
      },
      async renameDomain() {
        if (this.experiment.domain !== this.newDomain) {
          if (!validateDomain(this.newDomain)) {
            this.$notify({
              type: 'error',
              text: this.$t('validations.domain.invalid'),
            });
            this.newDomain = this.campaign.domain;
            return;
          }

          const {
            data: { domainUsageCount },
          } = await this.$apollo.query({
            query: GET_DOMAIN_USAGE_COUNT,
            variables: {
              domainId: this.experiment.domainId,
            },
          });

          if (domainUsageCount > 1) {
            this.$modal.show('dialog', {
              title: this.$t('domainUsedRename', { count: domainUsageCount }),
              buttons: [
                {
                  title: this.$t('yes'),
                  class: getBrandedClassString({ primary: true }, 'mr-3'),
                  handler: () => this.rename(),
                },
                {
                  title: this.$t('cancel'),
                  class: getBrandedClassString({ secondary: true }),
                  handler: () => {
                    this.newDomain = this.experiment.domain;
                    this.$modal.hide('dialog');
                  },
                },
              ],
            });
          } else {
            this.rename();
          }
        }
      },
      async rename() {
        const domainWithoutProtocol = removeProtocolFromDomain(this.newDomain);

        const {
          data: { renameDomain },
        } = await this.$apollo.mutate({
          mutation: RENAME_DOMAIN,
          variables: {
            domainId: this.experiment.domainId,
            domain: domainWithoutProtocol,
          },
        });

        if (renameDomain) {
          this.experiment.domain = domainWithoutProtocol;
          this.newDomain = this.experiment.domain;
          this.$refs.dynamicDomainInput.blur();
          this.$notify({
            type: 'success',
            text: this.$t('notifications.renameSuccess'),
          });
          this.$modal.hide('dialog');
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.renameError'),
          });
        }
      },
      onDomainRefetch() {
        this.$apollo.queries.domainCount.refetch();
      },
      getPreviewUrl(variantId) {
        const userId = this.$route.params.userId;
        return `${runTimeConfig.VUE_APP_CDN_URL}/public/${userId}/${variantId}/page1.png`;
      },
      addCampaign(group) {
        const campaignsAlreadyInGroup =
          this.experiment.groups
            .find((g) => g._id === group._id)
            ?.campaigns?.map?.((campaign) => campaign._id) || [];
        this.$modal.show('add-campaign-to-experiment', {
          domain: this.experiment.domain,
          domainId: this.experiment.domainId,
          groupId: group._id,
          experimentId: this.experiment._id,
          campaignsAlreadyInGroup,
        });
      },
      async handleAddCampaign({ campaignId, groupId }) {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_CAMPAIGN_TO_EXPERIMENT,
          variables: {
            experimentId: this.experimentId,
            groupId,
            campaignId,
          },
        });

        if (data?.addCampaignToExperiment?.success) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.addSuccess'),
          });
          const group = this.experiment.groups.find((group) => group._id === groupId);
          group.campaigns.push(data.addCampaignToExperiment.campaign);
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.addError'),
          });
        }
      },
      removeDeletedCampaign(groupId, campaignId) {
        const group = this.experiment.groups.find((group) => group._id === groupId);
        const campaignIndex = group.campaigns.findIndex((campaign) => campaign.id === campaignId);
        group.campaigns.splice(campaignIndex, 1);
      },
      removeDeletedGroup(groupId, newGroups) {
        const groupIndex = this.experiment.groups.findIndex((group) => group._id === groupId);
        this.experiment.groups.splice(groupIndex, 1);
        this.experiment.groups = this.matchCampaignsToNewGroups(newGroups);
      },
      async removeCampaignFromGroup(campaignId, groupId) {
        const { data } = await this.$apollo.mutate({
          mutation: REMOVE_CAMPAIGN_FROM_EXPERIMENT,
          variables: {
            experimentId: this.experimentId,
            groupId,
            campaignId,
          },
        });

        if (data?.removeCampaignFromExperiment?.success) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.removeSuccess'),
          });
          this.removeDeletedCampaign(groupId, campaignId);
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.deleteError'),
          });
        }
      },
      matchCampaignsToNewGroups(groups) {
        return groups.map((group) => {
          const oldGroup = this.experiment.groups.find((oldGroup) => oldGroup._id === group._id);
          return {
            ...group,
            ...(oldGroup?.campaigns?.length && { campaigns: oldGroup.campaigns }),
          };
        });
      },
      async addGroupToExperiment() {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_GROUP_TO_EXPERIMENT,
          variables: {
            experimentId: this.experimentId,
          },
        });

        if (data?.addGroupToExperiment?.success) {
          this.experiment.groups = this.matchCampaignsToNewGroups(data.addGroupToExperiment.groups);
          this.$notify({
            type: 'success',
            text: this.$t('notifications.addSuccess'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.addError'),
          });
        }
      },
      async removeGroupFromExperiment(groupId) {
        const { data } = await this.$apollo.mutate({
          mutation: DELETE_GROUP_FROM_EXPERIMENT,
          variables: {
            experimentId: this.experimentId,
            groupId,
          },
        });

        if (data?.deleteGroupFromExperiment?.success) {
          this.removeDeletedGroup(groupId, data.deleteGroupFromExperiment.groups);
          this.$notify({
            type: 'success',
            text: this.$t('notifications.deleteSuccess'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.deleteError'),
          });
        }
      },
      async startExperiment() {
        const { data } = await this.$apollo.mutate({
          mutation: START_EXPERIMENT,
          variables: {
            experimentId: this.experimentId,
          },
        });

        if (data?.startExperiment?.success) {
          this.experiment.status = 'RUNNING';

          this.experiment.startedAt = moment.utc();

          this.experiment.groups.forEach((group) => {
            group.campaigns.forEach((campaign) => {
              campaign.status = 'active';
            });
          });
        } else {
          console.error('Failed to start experiment');
        }
      },
      async stopExperiment() {
        this.$modal.show('end-experiment');
      },
      async handleEndExperiment() {
        const { data } = await this.$apollo.mutate({
          mutation: END_EXPERIMENT,
          variables: {
            experimentId: this.experimentId,
          },
        });

        if (data?.endExperiment?.success) {
          this.experiment.status = 'FINISHED';
          this.experiment.finishedAt = moment.utc();
          this.experiment.groups.forEach((group) => {
            group.campaigns.forEach((campaign) => {
              campaign.status = 'inactive';
            });
          });
        } else {
          console.error('Failed to start experiment');
        }
      },
      updateTrafficShare() {
        if (this.experiment?.status === 'DRAFT' && this.experiment.groups.length >= 2) {
          this.$modal.show('experiment-traffic-share', { experiment: this.experiment });
        }
      },
      updateGroups(event) {
        const { newGroups } = event;
        this.experiment.groups.forEach((group) => {
          group.trafficShare = newGroups.find(
            (newGroup) => newGroup._id === group._id,
          )?.trafficShare;
        });
      },
      formatConversionRate(stats) {
        const conversionRate = stats?.conversionRate || 0;
        return `${numeral(conversionRate * 100).format('0.00')}%`;
      },
    },
    apollo: {
      domainCount: {
        query: GET_DOMAINS_COUNT,
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .experiment-page .title
    margin-bottom: 32px
  .experiment-actions
    display: flex
    align-items: center
  .experiment-header
    flex-direction: column

  .experiment-table
    .experiment-campaigns
      display: flex
      flex-direction: row
      align-items: center

    .brand-table-td-0
      justify-content: flex-start!important
    .brand-table-td-1,
    .brand-table-td-2
      text-align: left!important
      justify-content: flex-start!important

    .brand-table-th-0,
    .brand-table-td-0
      flex: 0 0 10%!important
    .brand-table-th-1,
    .brand-table-td-1
      flex: 0 0 35%!important

    .brand-table-th-2,
    .brand-table-td-2
      flex: 0 0 10%!important
  .experiment-table-col-trafficShare,
  .experiment-table-col-status,
  .experiment-table-col-campaigns
    span
      justify-content: flex-start!important
  .groups-header
    justify-content: space-between
    margin-bottom: 2.25rem

  .domain-editing
    cursor: text
    display: inline-block
    color: #969BA0
    font-size: 0.9375rem

    &:hover
      background-color: $om-gray-100

  .bordered-content
    padding: .75rem
    border: 1px solid $om-gray-300
    border-radius: 4px
    margin-bottom: 2.5rem
  .visitor-groups
    .brand-table-tr
      &:hover
        .remove-campaign,
        .remove-group
          display: block
      .remove-campaign,
      .remove-group
        display: none
    &.bordered-content
      padding: 1.5rem 1rem

      .brand-table-tr-header
        padding: 0
        margin: 0
        background: $om-gray-200
        min-height: 3.5rem
        border-radius: 4px
    .add-campaign
      justify-content: flex-end
      font-weight: 700
      color: $om-orange
      cursor: pointer
  .start-stop-experiment
    display: flex
    justify-content: space-between

    .experiment-status
      display: flex
    .experiment-duration
      display: flex
      flex-direction: column
      margin-left: 1.4rem

      .body-text
        color: $om-gray-600
</style>
<style lang="sass">
  .goal-select .select-wrapper
    min-width: 20rem

  .experiment-table
    &.brand-table .tbody .brand-table-tr,
    &.brand-table .thead .tr
      border-color: transparent
    &.brand-table .thead .tr .th span
      padding-bottom: 0.5rem
    &.brand-table .tbody .brand-table-tr .td
      justify-content: flex-end
      display: flex
      &.experiment-table-row-campaign
        font-weight: 400
  .visitor-groups .heading-3
    font-weight: 400

  .group-kebab
    margin-right: 1rem
  .insert-code
    width: 1rem
    height: auto
    margin-left: 0.5rem
    cursor: pointer
</style>
